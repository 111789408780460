<template>
    <div>
        <svg
            version="1.1"
            id="&#x56FE;&#x5C42;_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 500 500"
            style="enable-background: new 0 0 500 500"
            xml:space="preserve"
        >
            <g>
                <linearGradient
                    id="SVGID_1_"
                    gradientUnits="userSpaceOnUse"
                    x1="-47.0522"
                    y1="3152.7556"
                    x2="49.5006"
                    y2="3152.7556"
                    gradientTransform="matrix(0.7071 -0.7071 0.7071 0.7071 -1835.067 -1829.2438)"
                >
                    <stop offset="0" style="stop-color: #ffb702" />
                    <stop offset="0.4918" style="stop-color: #ff9300" />
                    <stop offset="1" style="stop-color: #d57900" />
                </linearGradient>
                <path
                    class="rocket-icon-path"
                    style="fill: url(#SVGID_1_)"
                    d="M304.041,365.488c0,0,4.981,31.752,28.017,51.052s58.523,31.129,60.703,28.95
		c2.179-2.179-27.394-20.545-27.083-22.725c0.311-2.179,32.686,7.16,72.22,58.523c-7.471-24.281-16.81-55.411-45.76-86.229
		c5.603,4.358,62.259,49.185,63.815,50.741c-0.623-5.603-10.273-50.741-69.419-81.248c6.226-0.623,53.854,9.65,56.344,10.895
		c-3.113-4.358-30.507-39.223-37.044-55.099c-6.537-15.876-27.394-29.262-27.394-29.262L304.041,365.488z"
                />

                <linearGradient
                    id="SVGID_2_"
                    gradientUnits="userSpaceOnUse"
                    x1="-19.7954"
                    y1="3109.6123"
                    x2="27.1567"
                    y2="3109.6123"
                    gradientTransform="matrix(0.7071 -0.7071 0.7071 0.7071 -1835.067 -1829.2438)"
                >
                    <stop offset="0" style="stop-color: #ff810d" />
                    <stop offset="0.4918" style="stop-color: #f46600" />
                    <stop offset="0.9696" style="stop-color: #e44900" />
                </linearGradient>
                <path
                    class="rocket-icon-path"
                    style="fill: url(#SVGID_2_)"
                    d="M322.067,350.576c0,0,2.422,15.441,13.624,24.826s28.459,15.138,29.519,14.078
		c1.06-1.06-13.321-9.991-13.17-11.051c0.151-1.06,15.895,3.482,35.12,28.459c-3.633-11.807-8.174-26.945-22.253-41.932
		c2.725,2.119,30.276,23.918,31.032,24.675c-0.303-2.725-4.995-24.675-33.757-39.51c3.028-0.303,26.188,4.693,27.399,5.298
		c-1.514-2.119-14.835-19.074-18.014-26.794s-13.321-14.23-13.321-14.23L322.067,350.576z"
                />
                <path
                    class="rocket-icon-path"
                    style="fill: #fec500"
                    d="M329.442,343.2c0,0,1.276,8.136,7.179,13.081c5.902,4.945,14.995,7.976,15.554,7.418
		c0.558-0.558-7.019-5.264-6.939-5.823c0.08-0.558,8.375,1.835,18.505,14.995c-1.914-6.221-4.307-14.198-11.725-22.094
		c1.436,1.117,15.952,12.602,16.351,13.001c-0.16-1.436-2.632-13.001-17.787-20.818c1.595-0.16,13.799,2.473,14.437,2.792
		c-0.798-1.117-7.817-10.05-9.492-14.118s-7.019-7.498-7.019-7.498L329.442,343.2z"
                />
                <path
                    class="rocket-icon-path"
                    style="fill: #87080e"
                    d="M131.584,272.1c0,0-7.782,26.46-1.868,46.694c5.915,20.234,11.829,47.317,93.077,93.077
		c-3.424-19.612,5.603-66.617,12.763-71.287C221.859,331.869,131.584,272.1,131.584,272.1z"
                />
                <path
                    class="rocket-icon-path"
                    style="fill: #87080e"
                    d="M284.974,118.71c0,0,26.46-7.782,46.694-1.868s47.317,11.829,93.077,93.077
		c-19.612-3.424-66.617,5.603-71.287,12.763C344.743,208.985,284.974,118.71,284.974,118.71z"
                />

                <linearGradient
                    id="SVGID_3_"
                    gradientUnits="userSpaceOnUse"
                    x1="-88.9746"
                    y1="2859.7776"
                    x2="116.5122"
                    y2="2859.7776"
                    gradientTransform="matrix(0.7071 -0.7071 0.7071 0.7071 -1835.067 -1829.2438)"
                >
                    <stop offset="0.1474" style="stop-color: #e0ebff" />
                    <stop offset="0.9522" style="stop-color: #b8cbfe" />
                </linearGradient>
                <path
                    class="rocket-icon-path"
                    style="fill: url(#SVGID_3_)"
                    d="M125.981,270.232C49.402,193.654,12.047,68.513,46.912,33.648
		c44.204-44.204,184.909,25.526,231.292,71.287s80.937,91.521,103.35,176.816c3.113,13.074-40.468,56.656-40.468,56.656
		s-25.526,24.281-42.647,33.309S180.769,325.02,125.981,270.232z"
                />

                <linearGradient
                    id="SVGID_4_"
                    gradientUnits="userSpaceOnUse"
                    x1="-48.6726"
                    y1="2826.3196"
                    x2="74.1586"
                    y2="2826.3196"
                    gradientTransform="matrix(0.7071 -0.7071 0.7071 0.7071 -1835.067 -1829.2438)"
                >
                    <stop offset="0" style="stop-color: #cadcfe" />
                    <stop offset="1" style="stop-color: #a5c5ff" />
                </linearGradient>
                <circle
                    style="fill: url(#SVGID_4_)"
                    cx="173.609"
                    cy="159.1"
                    r="73.079"
                     class="rocket-icon-path"
                />

                <linearGradient
                    id="SVGID_5_"
                    gradientUnits="userSpaceOnUse"
                    x1="-19.3514"
                    y1="2826.022"
                    x2="48.3438"
                    y2="2826.6191"
                    gradientTransform="matrix(0.7071 -0.7071 0.7071 0.7071 -1835.067 -1829.2438)"
                >
                    <stop offset="0" style="stop-color: #0600d1" />
                    <stop offset="1" style="stop-color: #020264" />
                </linearGradient>
                <circle
                    style="fill: url(#SVGID_5_)"
                    cx="173.609"
                    cy="159.1"
                    r="46.225"
                     class="rocket-icon-path"
                />
                <path
                    class="rocket-icon-path"
                    style="fill: #a40629"
                    d="M291.59,287.665c-38.234,31.183-70.321,46.303-77.961,49.684
		c39.322,24.107,75.202,39.432,84.81,34.366c8.299-4.376,18.574-12.336,26.958-19.378l16.312-14.553l13.816-14.766
		c12.375-13.869,27.933-33.272,26.03-41.267c-7.956-30.277-17.443-55.569-28.445-77.651
		C353.042,204.28,335.399,251.934,291.59,287.665z"
                />

                <linearGradient
                    id="SVGID_6_"
                    gradientUnits="userSpaceOnUse"
                    x1="8.3626"
                    y1="2998.6772"
                    x2="18.6798"
                    y2="2998.6772"
                    gradientTransform="matrix(0.7071 -0.7071 0.7071 0.7071 -1835.067 -1829.2438)"
                >
                    <stop offset="0" style="stop-color: #a80263" />
                    <stop offset="1" style="stop-color: #7e3167" />
                </linearGradient>
                <path
                    class="rocket-icon-path"
                    style="fill: url(#SVGID_6_)"
                    d="M243.961,230.075c0,0,63.815,48.873,103.661,93.7c2.179,2.179,3.242,4.243,2.091,5.394
		c-0.545,0.545-2.111,2.111-3.699,3.697c-1.761,1.759-3.547,3.542-3.996,3.984c-1.868,1.868-4.981-1.868-10.895-7.16
		C279.138,277.703,243.961,230.075,243.961,230.075z"
                />
                <path
                    class="rocket-icon-path"
                    style="fill: #a40629"
                    d="M46.912,33.648c-20.274,20.274-16.125,71.074,5.954,125.1c0.015-0.029,23.939-47.284,51.013-72.491
		c27.066-25.199,68.064-46.611,68.114-46.637C122.169,19.022,70.639,9.921,46.912,33.648z"
                />
            </g>
        </svg>
    </div>
</template>
<script>
function setStyle(target, styles) {
    for (const k in styles) {
        target.style[k] = styles[k];
    }
}
export default {
    name: "RocketSvgShow",
    methods: {
        initShow() {
            const pathElements = document.querySelectorAll(".rocket-icon-path");
            let maxPath = 0;
            for (let i = 0; i < pathElements.length; i++) {
                const pathElement = pathElements[i];
                maxPath = Math.max(maxPath, pathElement.getTotalLength());
            }
            for (let i = 0; i < pathElements.length; i++) {
                const pathElement = pathElements[i];
                const fill = pathElement.getAttribute("style").split(":")[1];
                const path = {
                    fill: "none",
                    animation: `animation${i} 0.5s linear 1 forwards`,
                };
                setStyle(pathElement, path);
                document.styleSheets[0].insertRule(
                    `
            @keyframes animation${i} {
            0% {
              fill: white;
              stroke: #0c0426;
              stroke-dasharray: ${maxPath};
              stroke-dashoffset: ${maxPath};
            }
            50% {
              fill: white;
              stroke: #0c0426;
              stroke-dasharray: ${maxPath};
              stroke-dashoffset: 0;
            }
            100% {
              fill: ${fill};
              stroke: ${fill};
            }
            }
          `,
                );
            }
        },
    },
    mounted() {
        this.initShow();
    },
};
</script>
