// 深圳船期
import { request } from "@/utils/request.js";


/**
 * @description 深圳船期订阅
 * @typedef {Object} param 
 * @property {string} param.code 类型,NB,SH,SZ,这里是深圳
 * @property {string} param.companyId 公司id
 * @property {string} [param.remark] 备注
 * @property {string} param.role 角色 当前用户角色/PERSONAL/ENTERPISE
 * @property {string} param.vesselsName 船名
 * @property {string} param.voyage 航次
 * @param {param} param
 * @returns 
 */
export const szShipQuery=(param)=>{
    return request("/vessels/szVessels/subscribe",'post',param)
}

/**
 * @description 深圳船期列表分页查询
 * @typedef {Object} pageVesselsDTO
 * @property {string} pageVesselsDTO.code 类型,NB,SH,SZ,这里是深圳
 * @property {number} pageVesselsDTO.current 当前页
 * @property {number} pageVesselsDTO.size 每页显示条数
 * @property {string} [pageVesselsDTO.vesselsName] 船名
 * @property {string} [pageVesselsDTO.voyage] 航次
 * @property {string} [pageVesselsDTO.startTime] 开始时间
 * @property {string} [pageVesselsDTO.endTime] 结束时间
 * @property {string} [pageVesselsDTO.ctnApplyBeginTime] 是否是今日开港
 * @property {string} [pageVesselsDTO.ctnApplyEndTime] 是否是今日截港
 * @property {string} [pageVesselsDTO.closeTime] 是否是今日截单/截关
 * @property {string} [pageVesselsDTO.arrivalTime] 是否是今日抵港
 * @property {string} [pageVesselsDTO.leaveTime] 是否是今日离港
 * @param {pageVesselsDTO} param 
 */
export const szShipListPageQuery=(param)=>{
    return request("/vessels/szVessels/selectPage",'post',param)
}


/**
 * @description 获取已订阅的船名航次
 * @typedef {Object} param 
 * @property {string} param.code 类型,NB,SH,SZ,这里是深圳
 * @param {param} param
 */

export const szShipSubscribed=(param)=>{
    return request("/vessels/szVessels/queryVeVo",'get',param,{
        header:{
            'Content-Type':'application/x-www-form-urlencoded'
        }
    })
}


/**
 * @description 批量删除
 * @param {Array<number>} ids
 */
export const szShipBatchDelete=(ids)=>{
    return request(`/blno/userBlnoSz/deleted?ids=${ids.ids}`,'post',ids,{
        headers:{
            'Content-Type':'application/x-www-form-urlencoded'
        }
    },false,true)
}


/**
 * @description 批量置顶
 * @param {Array<number>} ids
 */
export const szShipBatchTop=(ids)=>{
    return request("/blno/userBlnoSz/toppingList",'post',ids)
}

/**
 * @description 取消置顶
 * @param {Array<number>} ids
 */
export const szShipBatchCancelTop=(ids)=>{
    return request("/blno/userBlnoSz/unToppingList",'post',ids)
}

/**
 * @description 修改备注
 * @param {number} id
 * @param {string} remark
 */
export const szShipUpdateRemark=(param)=>{
    return request("/blno/userBlnoSz/updateRemark",'post',param,{
        header:{
            'Content-Type':'application/x-www-form-urlencoded'
        }
    })
}