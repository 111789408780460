var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',_vm._b({staticClass:"ship-dialog",class:`${_vm.showTab}_dialog`,attrs:{"visible":_vm.dialogVisible,"close-on-press-escape":false,"close-on-click-modal":false,"attach-to-body":""},on:{"update:visible":function($event){_vm.dialogVisible=$event},"confirm":()=>{
        this.$emit('confirm')
    },"close":()=>{
        this.$emit('close')
    },"cancel":()=>{
        this.$emit('cancel')
    }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._t("title",function(){return [_c('div',{staticClass:"header-title",class:`${_vm.showTab}_title`},[_c('div',{staticClass:"id-role",style:({
                        left: `calc(50% - ${_vm.textWidth / 2}px)`,
                    })},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.needImg&&_vm.imgType!=='ship')?_c('img',{class:`${_vm.size}-${_vm.imgType}`,attrs:{"src":_vm.imgUrl,"alt":""}}):_vm._e(),(_vm.dialogVisible&&_vm.needImg&&_vm.imgType==='ship')?_c('RocketSvgShow',{class:`${_vm.size}-${_vm.imgType}`}):_vm._e(),_c('img',{staticClass:"pop-right",attrs:{"src":_vm.popupRight,"alt":"","width":_vm.size === 'large' ? 250 : 150}})],1)]})]},proxy:true},{key:"footer",fn:function(){return [_vm._t("footer")]},proxy:true}],null,true)},'el-dialog',_vm.$attrs,false),[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }